import { default as _91_46_46_46slug_938iRwgAAmWgMeta } from "/home/ecomadm/app/releases/37/modules/rawlplug-cms/pages/[...slug].vue?macro=true";
import { default as indexSFqvHhIuYdMeta } from "/home/ecomadm/app/releases/37/modules/rawlplug-team/pages/[locale]/about-us/index.vue?macro=true";
import { default as addressesGXYEm7P4EXMeta } from "/home/ecomadm/app/releases/37/modules/rawlplug-customer/pages/[locale]/account/addresses.vue?macro=true";
import { default as companyh0i08vyaHnMeta } from "/home/ecomadm/app/releases/37/modules/rawlplug-customer/pages/[locale]/account/company.vue?macro=true";
import { default as contactdsJTzuTWECMeta } from "/home/ecomadm/app/releases/37/modules/rawlplug-customer/pages/[locale]/account/contact.vue?macro=true";
import { default as indexgPsflfSLWGMeta } from "/home/ecomadm/app/releases/37/modules/rawlplug-customer/pages/[locale]/account/index.vue?macro=true";
import { default as listsX7D315OFKzMeta } from "/home/ecomadm/app/releases/37/modules/rawlplug-customer/pages/[locale]/account/lists.vue?macro=true";
import { default as _91_46_46_46slug_93KnCXxaC3NuMeta } from "/home/ecomadm/app/releases/37/modules/rawlplug-customer/pages/[locale]/account/order/[...slug].vue?macro=true";
import { default as ordersfZeIBrdNXtMeta } from "/home/ecomadm/app/releases/37/modules/rawlplug-customer/pages/[locale]/account/orders.vue?macro=true";
import { default as payments_45and_45billingsqaDCi7Vj0Meta } from "/home/ecomadm/app/releases/37/modules/rawlplug-customer/pages/[locale]/account/payments-and-billing.vue?macro=true";
import { default as add_45userFOuWUCKYd3Meta } from "/home/ecomadm/app/releases/37/modules/rawlplug-customer/pages/[locale]/account/permissions/add-user.vue?macro=true";
import { default as editxyQMPshOvEMeta } from "/home/ecomadm/app/releases/37/modules/rawlplug-customer/pages/[locale]/account/permissions/roles/[id]/edit.vue?macro=true";
import { default as indexWtWyLfSWOVMeta } from "/home/ecomadm/app/releases/37/modules/rawlplug-customer/pages/[locale]/account/permissions/roles/index.vue?macro=true";
import { default as newLCs2QNXt7MMeta } from "/home/ecomadm/app/releases/37/modules/rawlplug-customer/pages/[locale]/account/permissions/roles/new.vue?macro=true";
import { default as editwmLBWOWrzGMeta } from "/home/ecomadm/app/releases/37/modules/rawlplug-customer/pages/[locale]/account/permissions/users/[id]/edit.vue?macro=true";
import { default as indexr1xJpfuxrvMeta } from "/home/ecomadm/app/releases/37/modules/rawlplug-customer/pages/[locale]/account/permissions/users/index.vue?macro=true";
import { default as profileQDhrFgWrlVMeta } from "/home/ecomadm/app/releases/37/modules/rawlplug-customer/pages/[locale]/account/profile.vue?macro=true";
import { default as accountSQBsxqycqNMeta } from "/home/ecomadm/app/releases/37/modules/rawlplug-customer/pages/[locale]/account.vue?macro=true";
import { default as _91slug_93uyaPoHez5NMeta } from "/home/ecomadm/app/releases/37/modules/rawlplug-ecom/pages/[locale]/category/[slug].vue?macro=true";
import { default as categoryVPjgvtD7MPMeta } from "/home/ecomadm/app/releases/37/modules/rawlplug-ecom/pages/[locale]/category.vue?macro=true";
import { default as cartiLTCy60MolMeta } from "/home/ecomadm/app/releases/37/modules/rawlplug-ecom/pages/[locale]/checkout/cart.vue?macro=true";
import { default as delivery_45and_45paymenti2GIYHWUziMeta } from "/home/ecomadm/app/releases/37/modules/rawlplug-ecom/pages/[locale]/checkout/delivery-and-payment.vue?macro=true";
import { default as summarySwlmRpbaKMMeta } from "/home/ecomadm/app/releases/37/modules/rawlplug-ecom/pages/[locale]/checkout/summary.vue?macro=true";
import { default as checkouts7dGp8w0gPMeta } from "/home/ecomadm/app/releases/37/modules/rawlplug-ecom/pages/[locale]/checkout.vue?macro=true";
import { default as createPasswordrxkwrZNxrlMeta } from "/home/ecomadm/app/releases/37/modules/rawlplug-customer/pages/[locale]/customer/account/createPassword.vue?macro=true";
import { default as resetPasswordHB8fq89G86Meta } from "/home/ecomadm/app/releases/37/modules/rawlplug-customer/pages/[locale]/customer/account/resetPassword.vue?macro=true";
import { default as indexj7JxRUCnI5Meta } from "/home/ecomadm/app/releases/37/modules/rawlplug-team/pages/[locale]/event/index.vue?macro=true";
import { default as indexFj4gI7pon0Meta } from "/home/ecomadm/app/releases/37/modules/rawlplug-team/pages/[locale]/newsletter/index.vue?macro=true";
import { default as productTbCGPpsOAnMeta } from "/home/ecomadm/app/releases/37/modules/rawlplug-ecom/pages/[locale]/product.vue?macro=true";
import { default as _91name_93Xqnq2ig1J5Meta } from "/home/ecomadm/app/releases/37/modules/rawlplug-team/pages/[locale]/reference-objects/[name].vue?macro=true";
import { default as indexQq2T6PVJqhMeta } from "/home/ecomadm/app/releases/37/modules/rawlplug-team/pages/[locale]/reference-objects/index.vue?macro=true";
import { default as registerfEHYoTUPQlMeta } from "/home/ecomadm/app/releases/37/modules/rawlplug-customer/pages/[locale]/register.vue?macro=true";
import { default as resolvefLW5gKEQ2rMeta } from "/home/ecomadm/app/releases/37/modules/rawlplug-ecom/pages/[locale]/resolve.vue?macro=true";
import { default as cmswfq7CxalvFMeta } from "/home/ecomadm/app/releases/37/modules/rawlplug-ecom/pages/[locale]/search/cms.vue?macro=true";
import { default as storeIjoT4BEFovMeta } from "/home/ecomadm/app/releases/37/modules/rawlplug-ecom/pages/[locale]/search/store.vue?macro=true";
import { default as searchABFohnZLMqMeta } from "/home/ecomadm/app/releases/37/modules/rawlplug-ecom/pages/[locale]/search.vue?macro=true";
import { default as DescriptionSection64fkHBuuMaMeta } from "/home/ecomadm/app/releases/37/modules/rawlplug-team/pages/[locale]/technical-helpdesk/components/DescriptionSection.vue?macro=true";
import { default as HeroSectionqKYPSDfXQgMeta } from "/home/ecomadm/app/releases/37/modules/rawlplug-team/pages/[locale]/technical-helpdesk/components/HeroSection.vue?macro=true";
import { default as indexaKBLF9HCVBMeta } from "/home/ecomadm/app/releases/37/modules/rawlplug-team/pages/[locale]/technical-helpdesk/index.vue?macro=true";
import { default as ChooseClientgqIAO92cF6Meta } from "/home/ecomadm/app/releases/37/modules/rawlplug-team/pages/[locale]/welcome/components/ClientsSection/ChooseClient/ChooseClient.vue?macro=true";
import { default as ChooseClientElementUnOSRuZEfTMeta } from "/home/ecomadm/app/releases/37/modules/rawlplug-team/pages/[locale]/welcome/components/ClientsSection/ChooseClient/ChooseClientElement/ChooseClientElement.vue?macro=true";
import { default as ClientsElementr7HFNF6m3XMeta } from "/home/ecomadm/app/releases/37/modules/rawlplug-team/pages/[locale]/welcome/components/ClientsSection/ClientsElement/ClientsElement.vue?macro=true";
import { default as ClientsSection26fFp1EatWMeta } from "/home/ecomadm/app/releases/37/modules/rawlplug-team/pages/[locale]/welcome/components/ClientsSection/ClientsSection.vue?macro=true";
import { default as HeroHeaderzsjDiBhhSXMeta } from "/home/ecomadm/app/releases/37/modules/rawlplug-team/pages/[locale]/welcome/components/HeroHeader/HeroHeader.vue?macro=true";
import { default as goToJoinRawlplugSectionHfJh0umNLFMeta } from "/home/ecomadm/app/releases/37/modules/rawlplug-team/pages/[locale]/welcome/components/JoinRawlplug/goToJoinRawlplugSection.ts?macro=true";
import { default as JoinRawlplugxfWQDMks2FMeta } from "/home/ecomadm/app/releases/37/modules/rawlplug-team/pages/[locale]/welcome/components/JoinRawlplug/JoinRawlplug.vue?macro=true";
import { default as JoinRawlplugFormJoSXWaynRGMeta } from "/home/ecomadm/app/releases/37/modules/rawlplug-team/pages/[locale]/welcome/components/JoinRawlplug/JoinRawlplugForm.vue?macro=true";
import { default as JoinRawlplugModalIM8hUuqJc7Meta } from "/home/ecomadm/app/releases/37/modules/rawlplug-team/pages/[locale]/welcome/components/JoinRawlplug/JoinRawlplugModal.vue?macro=true";
import { default as JoinRawlplugStickyHvyD58wgHIMeta } from "/home/ecomadm/app/releases/37/modules/rawlplug-team/pages/[locale]/welcome/components/JoinRawlplug/JoinRawlplugSticky.vue?macro=true";
import { default as SlideCta0sH3m7uuhsMeta } from "/home/ecomadm/app/releases/37/modules/rawlplug-team/pages/[locale]/welcome/components/slider/SlideCta.vue?macro=true";
import { default as SlideLefteawmQsGMkWMeta } from "/home/ecomadm/app/releases/37/modules/rawlplug-team/pages/[locale]/welcome/components/slider/SlideLeft.vue?macro=true";
import { default as SlideRight0lHKpXIgl6Meta } from "/home/ecomadm/app/releases/37/modules/rawlplug-team/pages/[locale]/welcome/components/slider/SlideRight.vue?macro=true";
import { default as SliderPagination3CTpTJmEbCMeta } from "/home/ecomadm/app/releases/37/modules/rawlplug-team/pages/[locale]/welcome/components/slider/SliderPagination.vue?macro=true";
import { default as SliderSectiong8Mx4ZYBj2Meta } from "/home/ecomadm/app/releases/37/modules/rawlplug-team/pages/[locale]/welcome/components/slider/SliderSection.vue?macro=true";
import { default as slidesuudLbxJUqJMeta } from "/home/ecomadm/app/releases/37/modules/rawlplug-team/pages/[locale]/welcome/components/slider/slides.ts?macro=true";
import { default as disableAutoScrollOnLastSectionkVgRCpbOFvMeta } from "/home/ecomadm/app/releases/37/modules/rawlplug-team/pages/[locale]/welcome/composables/disableAutoScrollOnLastSection.ts?macro=true";
import { default as usePaginationXNPaM9YGpdMeta } from "/home/ecomadm/app/releases/37/modules/rawlplug-team/pages/[locale]/welcome/composables/usePagination.ts?macro=true";
import { default as useStyleWithHeaderHeightGsFwEFZAzoMeta } from "/home/ecomadm/app/releases/37/modules/rawlplug-team/pages/[locale]/welcome/composables/useStyleWithHeaderHeight.ts?macro=true";
import { default as indexWa4teerL5aMeta } from "/home/ecomadm/app/releases/37/modules/rawlplug-team/pages/[locale]/welcome/index.vue?macro=true";
import { default as constantsbINb6Qm6UEMeta } from "/home/ecomadm/app/releases/37/modules/rawlplug-team/pages/[locale]/welcome/vendors/fullpage/constants.js?macro=true";
import { default as FullPageS0MvD8NBaQMeta } from "/home/ecomadm/app/releases/37/modules/rawlplug-team/pages/[locale]/welcome/vendors/fullpage/FullPage.vue?macro=true";
import { default as T3PagesnHPu8apW0Meta } from "/home/ecomadm/app/releases/37/node_modules/.pnpm/@t3headless+nuxt-typo3@2.0.6_vue@3.4.34/node_modules/@t3headless/nuxt-typo3/dist/runtime/pages/T3Page.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    meta: _91_46_46_46slug_938iRwgAAmWgMeta || {},
    component: () => import("/home/ecomadm/app/releases/37/modules/rawlplug-cms/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "locale-about-us",
    path: "/:locale()/about-us",
    component: () => import("/home/ecomadm/app/releases/37/modules/rawlplug-team/pages/[locale]/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: "account",
    path: "/:locale()/account",
    meta: accountSQBsxqycqNMeta || {},
    component: () => import("/home/ecomadm/app/releases/37/modules/rawlplug-customer/pages/[locale]/account.vue").then(m => m.default || m),
    children: [
  {
    name: addressesGXYEm7P4EXMeta?.name ?? "locale-account-addresses",
    path: "addresses",
    meta: addressesGXYEm7P4EXMeta || {},
    component: () => import("/home/ecomadm/app/releases/37/modules/rawlplug-customer/pages/[locale]/account/addresses.vue").then(m => m.default || m)
  },
  {
    name: companyh0i08vyaHnMeta?.name ?? "locale-account-company",
    path: "company",
    meta: companyh0i08vyaHnMeta || {},
    component: () => import("/home/ecomadm/app/releases/37/modules/rawlplug-customer/pages/[locale]/account/company.vue").then(m => m.default || m)
  },
  {
    name: contactdsJTzuTWECMeta?.name ?? "locale-account-contact",
    path: "contact",
    meta: contactdsJTzuTWECMeta || {},
    component: () => import("/home/ecomadm/app/releases/37/modules/rawlplug-customer/pages/[locale]/account/contact.vue").then(m => m.default || m)
  },
  {
    name: "locale-account",
    path: "",
    component: () => import("/home/ecomadm/app/releases/37/modules/rawlplug-customer/pages/[locale]/account/index.vue").then(m => m.default || m)
  },
  {
    name: listsX7D315OFKzMeta?.name ?? "locale-account-lists",
    path: "lists",
    meta: listsX7D315OFKzMeta || {},
    component: () => import("/home/ecomadm/app/releases/37/modules/rawlplug-customer/pages/[locale]/account/lists.vue").then(m => m.default || m)
  },
  {
    name: "locale-account-order-slug",
    path: "order/:slug+",
    component: () => import("/home/ecomadm/app/releases/37/modules/rawlplug-customer/pages/[locale]/account/order/[...slug].vue").then(m => m.default || m)
  },
  {
    name: ordersfZeIBrdNXtMeta?.name ?? "locale-account-orders",
    path: "orders",
    meta: ordersfZeIBrdNXtMeta || {},
    component: () => import("/home/ecomadm/app/releases/37/modules/rawlplug-customer/pages/[locale]/account/orders.vue").then(m => m.default || m)
  },
  {
    name: payments_45and_45billingsqaDCi7Vj0Meta?.name ?? "locale-account-payments-and-billing",
    path: "payments-and-billing",
    meta: payments_45and_45billingsqaDCi7Vj0Meta || {},
    component: () => import("/home/ecomadm/app/releases/37/modules/rawlplug-customer/pages/[locale]/account/payments-and-billing.vue").then(m => m.default || m)
  },
  {
    name: "locale-account-permissions-add-user",
    path: "permissions/add-user",
    meta: add_45userFOuWUCKYd3Meta || {},
    component: () => import("/home/ecomadm/app/releases/37/modules/rawlplug-customer/pages/[locale]/account/permissions/add-user.vue").then(m => m.default || m)
  },
  {
    name: "locale-account-permissions-roles-id-edit",
    path: "permissions/roles/:id()/edit",
    meta: editxyQMPshOvEMeta || {},
    component: () => import("/home/ecomadm/app/releases/37/modules/rawlplug-customer/pages/[locale]/account/permissions/roles/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "locale-account-permissions-roles",
    path: "permissions/roles",
    meta: indexWtWyLfSWOVMeta || {},
    component: () => import("/home/ecomadm/app/releases/37/modules/rawlplug-customer/pages/[locale]/account/permissions/roles/index.vue").then(m => m.default || m)
  },
  {
    name: "locale-account-permissions-roles-new",
    path: "permissions/roles/new",
    meta: newLCs2QNXt7MMeta || {},
    component: () => import("/home/ecomadm/app/releases/37/modules/rawlplug-customer/pages/[locale]/account/permissions/roles/new.vue").then(m => m.default || m)
  },
  {
    name: "locale-account-permissions-users-id-edit",
    path: "permissions/users/:id()/edit",
    meta: editwmLBWOWrzGMeta || {},
    component: () => import("/home/ecomadm/app/releases/37/modules/rawlplug-customer/pages/[locale]/account/permissions/users/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexr1xJpfuxrvMeta?.name ?? "locale-account-permissions-users",
    path: "permissions/users",
    meta: indexr1xJpfuxrvMeta || {},
    component: () => import("/home/ecomadm/app/releases/37/modules/rawlplug-customer/pages/[locale]/account/permissions/users/index.vue").then(m => m.default || m)
  },
  {
    name: profileQDhrFgWrlVMeta?.name ?? "locale-account-profile",
    path: "profile",
    meta: profileQDhrFgWrlVMeta || {},
    component: () => import("/home/ecomadm/app/releases/37/modules/rawlplug-customer/pages/[locale]/account/profile.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "category",
    path: "/:path/c/",
    component: () => import("/home/ecomadm/app/releases/37/modules/rawlplug-ecom/pages/[locale]/category.vue").then(m => m.default || m),
    children: [
  {
    name: "category",
    path: ":slug+",
    meta: _91slug_93uyaPoHez5NMeta || {},
    component: () => import("/home/ecomadm/app/releases/37/modules/rawlplug-ecom/pages/[locale]/category/[slug].vue").then(m => m.default || m)
  }
]
  },
  {
    name: checkouts7dGp8w0gPMeta?.name ?? "locale-checkout",
    path: "/:path/checkout/",
    meta: checkouts7dGp8w0gPMeta || {},
    component: () => import("/home/ecomadm/app/releases/37/modules/rawlplug-ecom/pages/[locale]/checkout.vue").then(m => m.default || m),
    children: [
  {
    name: cartiLTCy60MolMeta?.name ?? "locale-checkout-cart",
    path: "/:path/checkout/cart",
    meta: cartiLTCy60MolMeta || {},
    component: () => import("/home/ecomadm/app/releases/37/modules/rawlplug-ecom/pages/[locale]/checkout/cart.vue").then(m => m.default || m)
  },
  {
    name: "checkout-delivery-and-payment",
    path: "/:path/checkout/delivery-and-payment",
    meta: delivery_45and_45paymenti2GIYHWUziMeta || {},
    component: () => import("/home/ecomadm/app/releases/37/modules/rawlplug-ecom/pages/[locale]/checkout/delivery-and-payment.vue").then(m => m.default || m)
  },
  {
    name: "checkout-summary",
    path: "/:path/checkout/summary",
    meta: summarySwlmRpbaKMMeta || {},
    component: () => import("/home/ecomadm/app/releases/37/modules/rawlplug-ecom/pages/[locale]/checkout/summary.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "locale-customer-account-createPassword",
    path: "/:locale()/customer/account/createPassword",
    meta: createPasswordrxkwrZNxrlMeta || {},
    component: () => import("/home/ecomadm/app/releases/37/modules/rawlplug-customer/pages/[locale]/customer/account/createPassword.vue").then(m => m.default || m)
  },
  {
    name: "locale-customer-account-resetPassword",
    path: "/:locale()/customer/account/resetPassword",
    meta: resetPasswordHB8fq89G86Meta || {},
    component: () => import("/home/ecomadm/app/releases/37/modules/rawlplug-customer/pages/[locale]/customer/account/resetPassword.vue").then(m => m.default || m)
  },
  {
    name: "locale-event",
    path: "/:locale()/event",
    component: () => import("/home/ecomadm/app/releases/37/modules/rawlplug-team/pages/[locale]/event/index.vue").then(m => m.default || m)
  },
  {
    name: "locale-newsletter",
    path: "/:locale()/newsletter",
    component: () => import("/home/ecomadm/app/releases/37/modules/rawlplug-team/pages/[locale]/newsletter/index.vue").then(m => m.default || m)
  },
  {
    name: "product",
    path: "/:path/p/:slug",
    meta: productTbCGPpsOAnMeta || {},
    component: () => import("/home/ecomadm/app/releases/37/modules/rawlplug-ecom/pages/[locale]/product.vue").then(m => m.default || m)
  },
  {
    name: "locale-reference-objects-name",
    path: "/:locale()/reference-objects/:name()",
    meta: _91name_93Xqnq2ig1J5Meta || {},
    component: () => import("/home/ecomadm/app/releases/37/modules/rawlplug-team/pages/[locale]/reference-objects/[name].vue").then(m => m.default || m)
  },
  {
    name: "locale-reference-objects",
    path: "/:locale()/reference-objects",
    meta: indexQq2T6PVJqhMeta || {},
    component: () => import("/home/ecomadm/app/releases/37/modules/rawlplug-team/pages/[locale]/reference-objects/index.vue").then(m => m.default || m)
  },
  {
    name: registerfEHYoTUPQlMeta?.name ?? "locale-register",
    path: "/:locale()/register",
    meta: registerfEHYoTUPQlMeta || {},
    component: () => import("/home/ecomadm/app/releases/37/modules/rawlplug-customer/pages/[locale]/register.vue").then(m => m.default || m)
  },
  {
    name: "ecom-redirect-resolve",
    path: "/:locale/r/:type/:slug",
    meta: resolvefLW5gKEQ2rMeta || {},
    component: () => import("/home/ecomadm/app/releases/37/modules/rawlplug-ecom/pages/[locale]/resolve.vue").then(m => m.default || m)
  },
  {
    name: searchABFohnZLMqMeta?.name ?? "locale-search",
    path: "/:path/search",
    meta: searchABFohnZLMqMeta || {},
    component: () => import("/home/ecomadm/app/releases/37/modules/rawlplug-ecom/pages/[locale]/search.vue").then(m => m.default || m),
    children: [
  {
    name: cmswfq7CxalvFMeta?.name ?? "locale-search-cms",
    path: "/:path/search/cms",
    meta: cmswfq7CxalvFMeta || {},
    component: () => import("/home/ecomadm/app/releases/37/modules/rawlplug-ecom/pages/[locale]/search/cms.vue").then(m => m.default || m)
  },
  {
    name: storeIjoT4BEFovMeta?.name ?? "locale-search-store",
    path: "/:path/search/store",
    meta: storeIjoT4BEFovMeta || {},
    component: () => import("/home/ecomadm/app/releases/37/modules/rawlplug-ecom/pages/[locale]/search/store.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "locale-technical-helpdesk-components-DescriptionSection",
    path: "/:locale()/technical-helpdesk/components/DescriptionSection",
    component: () => import("/home/ecomadm/app/releases/37/modules/rawlplug-team/pages/[locale]/technical-helpdesk/components/DescriptionSection.vue").then(m => m.default || m)
  },
  {
    name: "locale-technical-helpdesk-components-HeroSection",
    path: "/:locale()/technical-helpdesk/components/HeroSection",
    component: () => import("/home/ecomadm/app/releases/37/modules/rawlplug-team/pages/[locale]/technical-helpdesk/components/HeroSection.vue").then(m => m.default || m)
  },
  {
    name: "locale-technical-helpdesk",
    path: "/:locale()/technical-helpdesk",
    component: () => import("/home/ecomadm/app/releases/37/modules/rawlplug-team/pages/[locale]/technical-helpdesk/index.vue").then(m => m.default || m)
  },
  {
    name: "locale-welcome-components-ClientsSection-ChooseClient-ChooseClient",
    path: "/:locale()/welcome/components/ClientsSection/ChooseClient/ChooseClient",
    component: () => import("/home/ecomadm/app/releases/37/modules/rawlplug-team/pages/[locale]/welcome/components/ClientsSection/ChooseClient/ChooseClient.vue").then(m => m.default || m)
  },
  {
    name: "locale-welcome-components-ClientsSection-ChooseClient-ChooseClientElement-ChooseClientElement",
    path: "/:locale()/welcome/components/ClientsSection/ChooseClient/ChooseClientElement/ChooseClientElement",
    component: () => import("/home/ecomadm/app/releases/37/modules/rawlplug-team/pages/[locale]/welcome/components/ClientsSection/ChooseClient/ChooseClientElement/ChooseClientElement.vue").then(m => m.default || m)
  },
  {
    name: "locale-welcome-components-ClientsSection-ClientsElement-ClientsElement",
    path: "/:locale()/welcome/components/ClientsSection/ClientsElement/ClientsElement",
    component: () => import("/home/ecomadm/app/releases/37/modules/rawlplug-team/pages/[locale]/welcome/components/ClientsSection/ClientsElement/ClientsElement.vue").then(m => m.default || m)
  },
  {
    name: "locale-welcome-components-ClientsSection-ClientsSection",
    path: "/:locale()/welcome/components/ClientsSection/ClientsSection",
    component: () => import("/home/ecomadm/app/releases/37/modules/rawlplug-team/pages/[locale]/welcome/components/ClientsSection/ClientsSection.vue").then(m => m.default || m)
  },
  {
    name: "locale-welcome-components-HeroHeader-HeroHeader",
    path: "/:locale()/welcome/components/HeroHeader/HeroHeader",
    component: () => import("/home/ecomadm/app/releases/37/modules/rawlplug-team/pages/[locale]/welcome/components/HeroHeader/HeroHeader.vue").then(m => m.default || m)
  },
  {
    name: "locale-welcome-components-JoinRawlplug-goToJoinRawlplugSection",
    path: "/:locale()/welcome/components/JoinRawlplug/goToJoinRawlplugSection",
    component: () => import("/home/ecomadm/app/releases/37/modules/rawlplug-team/pages/[locale]/welcome/components/JoinRawlplug/goToJoinRawlplugSection.ts").then(m => m.default || m)
  },
  {
    name: "locale-welcome-components-JoinRawlplug-JoinRawlplug",
    path: "/:locale()/welcome/components/JoinRawlplug/JoinRawlplug",
    component: () => import("/home/ecomadm/app/releases/37/modules/rawlplug-team/pages/[locale]/welcome/components/JoinRawlplug/JoinRawlplug.vue").then(m => m.default || m)
  },
  {
    name: "locale-welcome-components-JoinRawlplug-JoinRawlplugForm",
    path: "/:locale()/welcome/components/JoinRawlplug/JoinRawlplugForm",
    component: () => import("/home/ecomadm/app/releases/37/modules/rawlplug-team/pages/[locale]/welcome/components/JoinRawlplug/JoinRawlplugForm.vue").then(m => m.default || m)
  },
  {
    name: "locale-welcome-components-JoinRawlplug-JoinRawlplugModal",
    path: "/:locale()/welcome/components/JoinRawlplug/JoinRawlplugModal",
    component: () => import("/home/ecomadm/app/releases/37/modules/rawlplug-team/pages/[locale]/welcome/components/JoinRawlplug/JoinRawlplugModal.vue").then(m => m.default || m)
  },
  {
    name: "locale-welcome-components-JoinRawlplug-JoinRawlplugSticky",
    path: "/:locale()/welcome/components/JoinRawlplug/JoinRawlplugSticky",
    component: () => import("/home/ecomadm/app/releases/37/modules/rawlplug-team/pages/[locale]/welcome/components/JoinRawlplug/JoinRawlplugSticky.vue").then(m => m.default || m)
  },
  {
    name: "locale-welcome-components-slider-SlideCta",
    path: "/:locale()/welcome/components/slider/SlideCta",
    component: () => import("/home/ecomadm/app/releases/37/modules/rawlplug-team/pages/[locale]/welcome/components/slider/SlideCta.vue").then(m => m.default || m)
  },
  {
    name: "locale-welcome-components-slider-SlideLeft",
    path: "/:locale()/welcome/components/slider/SlideLeft",
    component: () => import("/home/ecomadm/app/releases/37/modules/rawlplug-team/pages/[locale]/welcome/components/slider/SlideLeft.vue").then(m => m.default || m)
  },
  {
    name: "locale-welcome-components-slider-SlideRight",
    path: "/:locale()/welcome/components/slider/SlideRight",
    component: () => import("/home/ecomadm/app/releases/37/modules/rawlplug-team/pages/[locale]/welcome/components/slider/SlideRight.vue").then(m => m.default || m)
  },
  {
    name: "locale-welcome-components-slider-SliderPagination",
    path: "/:locale()/welcome/components/slider/SliderPagination",
    component: () => import("/home/ecomadm/app/releases/37/modules/rawlplug-team/pages/[locale]/welcome/components/slider/SliderPagination.vue").then(m => m.default || m)
  },
  {
    name: "locale-welcome-components-slider-SliderSection",
    path: "/:locale()/welcome/components/slider/SliderSection",
    component: () => import("/home/ecomadm/app/releases/37/modules/rawlplug-team/pages/[locale]/welcome/components/slider/SliderSection.vue").then(m => m.default || m)
  },
  {
    name: "locale-welcome-components-slider-slides",
    path: "/:locale()/welcome/components/slider/slides",
    component: () => import("/home/ecomadm/app/releases/37/modules/rawlplug-team/pages/[locale]/welcome/components/slider/slides.ts").then(m => m.default || m)
  },
  {
    name: "locale-welcome-composables-disableAutoScrollOnLastSection",
    path: "/:locale()/welcome/composables/disableAutoScrollOnLastSection",
    component: () => import("/home/ecomadm/app/releases/37/modules/rawlplug-team/pages/[locale]/welcome/composables/disableAutoScrollOnLastSection.ts").then(m => m.default || m)
  },
  {
    name: "locale-welcome-composables-usePagination",
    path: "/:locale()/welcome/composables/usePagination",
    component: () => import("/home/ecomadm/app/releases/37/modules/rawlplug-team/pages/[locale]/welcome/composables/usePagination.ts").then(m => m.default || m)
  },
  {
    name: "locale-welcome-composables-useStyleWithHeaderHeight",
    path: "/:locale()/welcome/composables/useStyleWithHeaderHeight",
    component: () => import("/home/ecomadm/app/releases/37/modules/rawlplug-team/pages/[locale]/welcome/composables/useStyleWithHeaderHeight.ts").then(m => m.default || m)
  },
  {
    name: "locale-welcome",
    path: "/:locale()/welcome",
    component: () => import("/home/ecomadm/app/releases/37/modules/rawlplug-team/pages/[locale]/welcome/index.vue").then(m => m.default || m)
  },
  {
    name: "locale-welcome-vendors-fullpage-constants",
    path: "/:locale()/welcome/vendors/fullpage/constants",
    component: () => import("/home/ecomadm/app/releases/37/modules/rawlplug-team/pages/[locale]/welcome/vendors/fullpage/constants.js").then(m => m.default || m)
  },
  {
    name: "locale-welcome-vendors-fullpage-FullPage",
    path: "/:locale()/welcome/vendors/fullpage/FullPage",
    component: () => import("/home/ecomadm/app/releases/37/modules/rawlplug-team/pages/[locale]/welcome/vendors/fullpage/FullPage.vue").then(m => m.default || m)
  },
  {
    name: "page",
    path: "/:slug(.*)*",
    component: () => import("/home/ecomadm/app/releases/37/node_modules/.pnpm/@t3headless+nuxt-typo3@2.0.6_vue@3.4.34/node_modules/@t3headless/nuxt-typo3/dist/runtime/pages/T3Page.vue").then(m => m.default || m)
  }
]